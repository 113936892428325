import React, { useEffect, useState } from "react";

import { PositionProvider } from "./context/position";
import { DeviceProvider } from "./context/device";
import { DirectionsProvider } from "./context/directions";

import StoreLocator from "./components/StoreLocator/StoreLocator";

import { BackgroundProvider } from "./context/backgroundContext";
import { StoreLocatorConfiguration } from "./types/configuration";
import { toast, ToastContainer } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Store from "./types/stores";
import { initializeAnalytics, pageview } from "./utils/services/google/analytics";
import { GOOGLE_ANALYTICS_USER_ID } from "./constants/api";
import { ConfigurationProvider } from "./context/configuration";

interface AppProps {
  configuration: StoreLocatorConfiguration;
}

initializeAnalytics(GOOGLE_ANALYTICS_USER_ID);
pageview("StoreLocator/index.php");

export default function App({ configuration }: AppProps) {
  return (
    <ConfigurationProvider configuration={configuration}>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={3000} pauseOnHover={true} />
      <PositionProvider>
        <DeviceProvider>
          <DirectionsProvider>
            <BackgroundProvider>
              <StoreLocator />
            </BackgroundProvider>
          </DirectionsProvider>
        </DeviceProvider>
      </PositionProvider>
    </ConfigurationProvider>
  );
}
