import classNames from "classnames";
import React, { ChangeEvent, useEffect, useState } from "react";
import Store, { Service } from "../../types/stores";

import "./FiltersArea.css";

interface FiltersAreaProps {
    stores: Store[];
    allServices: Service[];
    preselectedServices: number[];
    setFilteredStores: React.Dispatch<React.SetStateAction<Store[]>>;
}

export default function FiltersArea({
    stores,
    setFilteredStores,
    preselectedServices,
    allServices,
}: FiltersAreaProps) {
    const [services, setServices] = useState<Service[]>(() => allServices.map(s => ({...s, selected: preselectedServices.includes(s.id)})));

    useEffect(() => {
        setFilteredStores(
            stores.filter(
                (s) =>
                    services.filter((serv) => serv.selected).length === 0 ||
                    services
                        .filter((serv) => serv.selected)
                        .some((serv) => s.services.includes(serv.id))
            )
        );
    }, [stores, services]);

    function handleServiceClick(e: ChangeEvent<HTMLInputElement>) {
        const serviceId = parseInt(e.target.value);
        setServices((prev) =>
            prev.map((s) => {
                if (s.id === serviceId) {
                    return { ...s, selected: !s.selected };
                }
                return s;
            })
        );
    }

    return (
        <div className="rt-filters-area">
            <ul>
                {services
                    .sort((s1, s2) => {
                        if (s1.order > s2.order) return 1;
                        if (s1.order < s2.order) return -1;
                        return 0;
                    })
                    .map((s) => (
                        <li
                            key={s.id}
                            className={classNames("rt-service", {
                                "rt-service--selected": s.selected,
                            })}
                        >
                            <input
                                type="checkbox"
                                value={s.id}
                                checked={s.selected}
                                onChange={handleServiceClick}
                                name={"service"}
                            />{" "}
                            <h5 className="rt-service-name">{s.name}</h5>
                        </li>
                    ))}
            </ul>
        </div>
    );
}
