import Position from "../types/position";

export enum Type {
    UPDATE_USER_POSITION_CONSENT = "UPDATE_USER_POSITION_CONSENT",
    UPDATE_USET_POSITION_CONSENT_MODAL_VISIBILITY = "UPDATE_USET_POSITION_CONSENT_MODAL_VISIBILITY",
    UPDATE_CURRENT_POSITION = "UPDATE_CURRENT_POSITION",
    UPDATE_USER_POSITION = "UPDATE_USER_POSITION",
}

export interface Action {
    type: Type;
}

interface UpdateUserPositionConsent extends Action {
    payload: { userPositionConsent: boolean };
}

interface UpdateUserPositionConsentModalVisibility extends Action {
    payload: { isUserPositionConsentModalVisible: boolean };
}

interface UpdateCurrentPosition extends Action {
    payload: { currentPosition: Position };
}

interface UpdateUserPosition extends Action {
    payload: {
        isUserGeolocated: boolean;
        userPosition: Position;
        currentPosition: Position;
    };
}

export function updateUserPositionConsent(
    userPositionConsent: boolean
): UpdateUserPositionConsent {
    return {
        type: Type.UPDATE_USER_POSITION_CONSENT,
        payload: { userPositionConsent },
    };
}
export function isUpdateUserPositionConsentAction(
    action: Action
): action is UpdateUserPositionConsent {
    return action.type === Type.UPDATE_USER_POSITION_CONSENT;
}

export function updateUserPositionConsentModalVisibility(
    isUserPositionConsentModalVisible: boolean
): UpdateUserPositionConsentModalVisibility {
    return {
        type: Type.UPDATE_USET_POSITION_CONSENT_MODAL_VISIBILITY,
        payload: { isUserPositionConsentModalVisible },
    };
}
export function isUpdateUserPositionConsentModalVisibleAction(
    action: Action
): action is UpdateUserPositionConsentModalVisibility {
    return action.type === Type.UPDATE_USET_POSITION_CONSENT_MODAL_VISIBILITY;
}

export function updateCurrentPosition(currentPosition: Position): UpdateCurrentPosition {
    return {
        type: Type.UPDATE_CURRENT_POSITION,
        payload: { currentPosition },
    };
}
export function isUpdateCurrentPositionAction(
    action: Action
): action is UpdateCurrentPosition {
    return action.type === Type.UPDATE_CURRENT_POSITION;
}


export function updateUserPosition(
    isUserGeolocated: boolean,
    userPosition: Position,
    currentPosition?: Position
): UpdateUserPosition {
    return {
        type: Type.UPDATE_USER_POSITION,
        payload: {
            isUserGeolocated,
            userPosition,
            currentPosition: currentPosition ? currentPosition : userPosition,
        },
    };
}
export function isUpdateUserPositionAction(action: Action): action is UpdateUserPosition {
    return action.type === Type.UPDATE_USER_POSITION;
}
