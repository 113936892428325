import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useReducer,
} from "react";
import {
    clearDirections as resetDirections,
    updateAutocompleteValue,
    updateDestinationStore,
    updateDirectionsInstructions,
    updateOriginPosition,
    updateTravelMode,
    updateUserPosition,
} from "../actions/directions";
import directionsReducer from "../reducers/directions";
import { Device } from "../types/device";
import { DirectionsData, DirectionsInfo, TravelMode } from "../types/directions";
import Position from "../types/position";
import Store from "../types/stores";
import { PLACEHOLDER_POSITION } from "../utils/position";
import { useConfiguration } from "./configuration";
import { useDevice } from "./device";
import { usePosition } from "./position";

export interface State {
    directionsActive: boolean;
    origin: Position;
    userPosition: Position;
    destinationStore: Store | null;
    directionsInfo: DirectionsInfo;
    autocompleteValue: string;
}

const directionsContextState: State = {
    directionsActive: false,
    origin: PLACEHOLDER_POSITION,
    userPosition: PLACEHOLDER_POSITION,
    destinationStore: null,
    directionsInfo: {
        travelMode: TravelMode.DRIVING,
        steps: [],
        time: "",
        distance: "",
    },
    autocompleteValue: "",
};

const exposedValues = {
    directions: directionsContextState,
    clearDirections: () => {},
    setOrigin: (origin: Position) => {},
    setDestinationStore: (destinationStore: Store) => {},
    setTravelMode: (travelMode: TravelMode) => {},
    setDirectionsInstructions: (directionsData: DirectionsData) => {},
    setAutocompleteValue: (autocompleteValue: string) => {},
};

const DirectionsContext = createContext(exposedValues);

const searchParams = new URLSearchParams(window.location.search);
const code = searchParams.get("code");

let shouldFetchDirections = !!code;

interface DirectionsProviderProps {
    children: ReactNode | ReactNode[];
}

export function DirectionsProvider({ children }: DirectionsProviderProps) {
    const { storesConfiguration: { stores } } = useConfiguration(); 
    
    const {
        position: { isUserGeolocated, defaultPosition, userPosition },
    } = usePosition();

    const currentDevice = useDevice();

    const [directions, dispatch] = useReducer(directionsReducer, {
        ...directionsContextState,
        origin: userPosition,
        userPosition,
    });

    useEffect(() => {
        dispatch(updateUserPosition(userPosition));
    }, [userPosition]);

    useEffect(() => {
        function updateDrivingDirections() {
            if (!shouldFetchDirections) return;
            if (stores.length === 0) return;
            const store = stores.find((s) => s.storeCode === code);
            if (!store) return;
            setDestinationStore(store);
            shouldFetchDirections = false;
        }
        
        updateDrivingDirections();

    }, [directions.origin, stores])

    useEffect(() => {
        if (currentDevice !== Device.DESKTOP && directions.directionsActive) {
            clearDirections();
        }
    }, [currentDevice, directions.directionsActive]);

    function clearDirections() {
        return dispatch(resetDirections());
    }

    function setOrigin(position: Position) {
        if (currentDevice === Device.DESKTOP) {
            return dispatch(updateOriginPosition(position));
        }
    }

    function setDestinationStore(destinationStore: Store) {
        if (currentDevice === Device.DESKTOP) {
            return dispatch(updateDestinationStore(destinationStore));
        }
    }

    function setTravelMode(travelMode: TravelMode) {
        if (currentDevice === Device.DESKTOP) {
            return dispatch(updateTravelMode(travelMode));
        }
    }

    function setDirectionsInstructions(directionsData: DirectionsData) {
        if (currentDevice === Device.DESKTOP) {
            return dispatch(updateDirectionsInstructions(directionsData));
        }
    }

    function setAutocompleteValue(autocompleteValue: string) {
        if (currentDevice === Device.DESKTOP) {
            return dispatch(updateAutocompleteValue(autocompleteValue));
        }
    }
    return (
        <DirectionsContext.Provider
            value={{
                directions,
                clearDirections,
                setOrigin,
                setDestinationStore,
                setTravelMode,
                setDirectionsInstructions,
                setAutocompleteValue,
            }}
        >
            {children}
        </DirectionsContext.Provider>
    );
}

export const useDirections = () => useContext(DirectionsContext);
