import React, { useEffect, useState } from "react";
import { RuntimeData } from "../../runtime";

import { map } from "../Map/MapView";

import "./RadiusBar.css";
import classNames from "classnames";
import { Range } from "../../types/position";

interface RadiusBarProps {
    ranges: Range[];
}

export default function RadiusBar({ ranges }: RadiusBarProps) {
    const [selectedRadius, setSelectedRadius] = useState(0);

    useEffect(() => {
        if (map) {
            map.addListener("zoom_changed", () => {
                setSelectedRadius(() => {
                    switch(map.getZoom()) {
                        case 12: return 10;
                        case 10: return 50;
                        case 8: return 100;
                        default: return 0;
                    }
                });
            });
        }
    }, [map]);

    return (
        <div className="rt-radius-bar">
            
            <ul>
                <li>
                    <span>{RuntimeData.translations.listView.radiusBar.text}</span>
                </li>
                {ranges.map((r) => (
                    <li
                        className={classNames("rt-radius", {
                            "rt-radius--selected": r.value === selectedRadius,
                        })}
                        key={r.value}
                        onClick={() =>{
                            setSelectedRadius(r.value)
                            map.setZoom(r.mapZoom);
                        }}
                    >
                        {r.text}
                    </li>
                ))}
            </ul>
        </div>
    );
}
