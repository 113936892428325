import ReactGA from "react-ga";

const DEFAULT_TRACKER_NAME = "gtm1";
const trackerNames = [DEFAULT_TRACKER_NAME];

// available categories
const STORE_LOCATOR = "StoreLocator";
const STORE = "Store";

// available actions
const GEO = "Geo";
const GEO_IP = "GeoIP";
const GEO_DEFAULT = "GeoDefault";
const CLICK = "Click";

// common labels
const SUCCESS = "Success";
const SUCCESS_COOKIES = "SuccessCookies";
const ERROR = "Error";

interface StoreDetails {
    storeCode: string;
    city: string;
    address: string;
}

export interface AnalyticsParams {
    language: string;
    device: string;
    storeDetails: StoreDetails;
}

let analyticsInitialized = false;

export function initializeAnalytics(analyticsUserId: string) {
    try {
        ReactGA.initialize(analyticsUserId, {
            alwaysSendToDefaultTracker: false,
            gaOptions: {
                name: DEFAULT_TRACKER_NAME,
                allowLinker: true,
                cookieDomain: "auto",
                siteSpeedSampleRate: 100,
            },
        });
        analyticsInitialized = true;
    } catch (e) {
        console.error(e);
    }
}

async function event(
    category: string,
    action: string,
    label: string,
    options: object = {}
) {
    try {
        if (!analyticsInitialized) throw new Error("Analytics has not been initialized.");
        ReactGA.event({ category, action, label, ...options }, trackerNames);
    } catch (e) {
        console.error("Analytics event error: ", (e as Error).toString());
    }
}

// pageview hit type
export async function pageview(page: string) {
    try {
        ReactGA.pageview(page, trackerNames);
    } catch (e) {
        console.error(e);
    }
}

export async function clickConsentModal(consent: boolean) {
    event(STORE_LOCATOR, GEO, consent ? "Agree" : "Disagree");
}

export async function geoSuccess() {
    event(STORE_LOCATOR, GEO, SUCCESS);
}

export async function geoSuccessCookie() {
    event(STORE_LOCATOR, GEO, SUCCESS_COOKIES);
}

export async function geoError() {
    event(STORE_LOCATOR, GEO, ERROR);
}

export async function geoIpSuccess() {
    event(STORE_LOCATOR, GEO_IP, SUCCESS);
}
export async function geoIpSuccessCookie() {
    event(STORE_LOCATOR, GEO_IP, SUCCESS_COOKIES);
}
export async function geoIpError() {
    event(STORE_LOCATOR, GEO_IP, ERROR);
}
export async function geoDefaultSuccess() {
    event(STORE_LOCATOR, GEO_DEFAULT, SUCCESS);
}
export async function geoDefaultSuccessCookie() {
    event(STORE_LOCATOR, GEO_DEFAULT, SUCCESS_COOKIES);
}
export async function clickFindNearestStores() {
    event(STORE_LOCATOR, CLICK, "FindNearestStore");
}
export async function clickFreeSearch() {
    event(STORE_LOCATOR, CLICK, "FreeSearch");
}
export async function clickFreeSearchDirections() {
    event(STORE_LOCATOR, CLICK, "FreeSearchDirections");
}
export async function clickFilteredSearch() {
    event(STORE_LOCATOR, CLICK, "FilteredSearch");
}

export enum EventName {
    LISTING_PHONE = "PhoneHomeListing",
    LISTING_WHATSAPP = "WhatsappHomeListing",
    LISTING_MAIL = "MailHomeListing",
    LISTING_DIRECTIONS = "DirectionsHomeListing",
    LISTING_DETAILS = "DetailsHomeListing",
    MAP_PHONE = "PhoneHomeMap",
    MAP_WHATSAPP = "WhatsappHomeMap",
    MAP_MAIL = "MailHomeMap",
    MAP_DIRECTIONS = "DirectionsHomeMap",
    MAP_DETAILS = "DetailsHomeMap",
}

// This function is only used for store-related events
function createLabel(
    eventName: string,
    language: string,
    device: string,
    storeDetails: StoreDetails
) {
    try {
        return `${eventName}-${storeDetails.storeCode}-${storeDetails.city} ${storeDetails.address}-${device}-SL-${language}`;
    } catch (e) {
        console.error("Analytics label-creation error: ", (e as Error).toString());
    }
    return `LabelCreationError-StoreCode=${storeDetails.storeCode}`;
}

export function createEventFunction(eventName: EventName) {
    return async function (ap: AnalyticsParams) {
        const label = createLabel(eventName, ap.language, ap.device, ap.storeDetails);
        event(STORE, CLICK, label);
    };
}

export const clickPhoneListing = createEventFunction(EventName.LISTING_PHONE);

export const clickPhoneMap = createEventFunction(EventName.MAP_PHONE);

export const clickWhatsappListing = createEventFunction(EventName.LISTING_WHATSAPP);

export const clickWhatsappMap = createEventFunction(EventName.MAP_WHATSAPP);

export const clickMailListing = createEventFunction(EventName.LISTING_MAIL);

export const clickMailMap = createEventFunction(EventName.MAP_MAIL);

export const clickDirectinsListing = createEventFunction(EventName.LISTING_DIRECTIONS);

export const clickDirectionsMap = createEventFunction(EventName.MAP_DIRECTIONS);

export const clickStoreDetailsListing = createEventFunction(EventName.LISTING_DETAILS);

export const clickStoreDetailsMap = createEventFunction(EventName.MAP_DETAILS);