import Store from "../types/stores";

export function sortByDistance(a: Store, b: Store) {
    if (a.distance === undefined || b.distance === undefined) return 0;
    if (a.distance > b.distance) return 1;
    if (a.distance < b.distance) return -1;
    return 0;
}

export function sortByPriority(a: Store, b: Store) {
    if (a.priority === undefined || b.priority === undefined) return sortByDistance(a, b);
    // lower number means higher priority
    if (a.priority > b.priority) return 1;
    if (a.priority < b.priority) return -1;
    return sortByDistance(a, b);
}
