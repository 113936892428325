import React, { createContext, ReactNode, useContext } from "react";
import { StoreLocatorConfiguration } from "../types/configuration";

interface ConfigurationProviderProps {
  children: ReactNode;
  configuration: StoreLocatorConfiguration;
}
const ConfigurationContext = createContext<StoreLocatorConfiguration | null>(null);

export function ConfigurationProvider({ children, configuration }: ConfigurationProviderProps) {
  return (
    <ConfigurationContext.Provider value={configuration}>{children}</ConfigurationContext.Provider>
  );
}

export const useConfiguration = () => {
  const context = useContext(ConfigurationContext);
  if (!context) throw new Error("Missing Configuration provider");
  return context;
};
