import React, { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import Position from "../types/position";
import { geolocateUser, PLACEHOLDER_POSITION } from "../utils/position";
import positionReducer from "../reducers/position";
import {
  updateCurrentPosition,
  updateUserPosition,
  updateUserPositionConsent,
  updateUserPositionConsentModalVisibility,
} from "../actions/position";
import { PositionConfiguration } from "../types/configuration";
import { getConsentData, getPositionData } from "../utils/local-storage";
import {
  LOCAL_STORAGE_CURRENT_POSITION,
  LOCAL_STORAGE_USER_POSIITON,
  LOCAL_STORAGE_USER_POSITION_CONSENT,
} from "../constants/position";
import { toast } from "react-toastify";
import { RuntimeData } from "../runtime";
import { useConfiguration } from "./configuration";

export interface State {
  isUserPositionConsentModalVisible: boolean;
  isUserPositionConsentActive: boolean;
  isUserGeolocated: boolean;
  currentPosition: Position;
  userPosition: Position;
  defaultPosition: Position;
}

let isUserPositionConsentActive = getConsentData(LOCAL_STORAGE_USER_POSITION_CONSENT);
if (!isUserPositionConsentActive) isUserPositionConsentActive = false;

let isUserGeolocated = !!getPositionData(LOCAL_STORAGE_USER_POSIITON);

const positionContextState: State = {
  isUserPositionConsentModalVisible: !isUserPositionConsentActive,
  isUserPositionConsentActive,
  isUserGeolocated,
  currentPosition: PLACEHOLDER_POSITION,
  userPosition: PLACEHOLDER_POSITION,
  defaultPosition: PLACEHOLDER_POSITION,
};

const exposedValues = {
  position: positionContextState,
  getUserPosition: () => {},
  setUserPositionConsent: (userPositionConsent: boolean) => {},
  setUserPositionConsentModalVisibility: (isUserPositionConsentModalVisible: boolean) => {},
  setCurrentPosition: (position: Position) => {},
  setUserPosition: (
    isUserGeolocated: boolean,
    userPosition: Position,
    currentPosition?: Position
  ) => {},
};
const PositionContext = createContext(exposedValues);

interface PositionProviderProps {
  children: ReactNode | ReactNode[];
}

export function PositionProvider({ children }: PositionProviderProps) {
  const { positionConfiguration: configuration } = useConfiguration();

  const [position, dispatch] = useReducer(positionReducer, {
    ...positionContextState,
    currentPosition: configuration.currentPosition,
    userPosition: configuration.userPosition,
    defaultPosition: configuration.defaultPosition,
  });

  useEffect(() => {
    getUserPosition();
  }, [position.isUserPositionConsentActive]);

  async function getUserPosition() {
    if (!position.isUserPositionConsentActive) {
      return setUserPositionConsentModalVisibility(true);
    }
    // fetching user position and, if provided, the current position
    const userPosition = await geolocateUser(configuration.defaultPosition);
    const userPositionIsDefaultPosition = userPosition === configuration.defaultPosition;
    const isUserGeolocated = !userPositionIsDefaultPosition;
    if (userPositionIsDefaultPosition)
      toast(RuntimeData.translations.warnings.position.genericError);

    let currentPosition = getPositionData(LOCAL_STORAGE_CURRENT_POSITION);
    // one-timer read
    if (currentPosition) localStorage.removeItem(LOCAL_STORAGE_CURRENT_POSITION);
    if (!currentPosition) currentPosition = userPosition;
    setUserPosition(isUserGeolocated, userPosition, currentPosition);
  }

  function setUserPositionConsent(userPositionConsent: boolean) {
    return dispatch(updateUserPositionConsent(userPositionConsent));
  }

  function setUserPositionConsentModalVisibility(isUserPositionConsentModalVisible: boolean) {
    dispatch(updateUserPositionConsentModalVisibility(isUserPositionConsentModalVisible));
  }

  function setCurrentPosition(position: Position) {
    return dispatch(updateCurrentPosition(position));
  }

  function setUserPosition(
    isUserGeolocated: boolean,
    userPosition: Position,
    currentPosition?: Position
  ) {
    return dispatch(updateUserPosition(isUserGeolocated, userPosition, currentPosition));
  }

  return (
    <PositionContext.Provider
      value={{
        position,
        getUserPosition,
        setUserPositionConsent,
        setUserPositionConsentModalVisibility,
        setCurrentPosition,
        setUserPosition,
      }}
    >
      {children}
    </PositionContext.Provider>
  );
}
export const usePosition = () => useContext(PositionContext);
