import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePosition } from "../../context/position";
import { AutocompleteErrors } from "../../errors";
import { createKmRange, createPosition } from "../../utils/position";
import { RuntimeData } from "../../runtime";
import { useDirections } from "../../context/directions";
import { useBackground } from "../../context/backgroundContext";
import RadiusBar from "../RadiusBar/RadiusBar";
import { useDevice } from "../../context/device";
import { Device } from "../../types/device";
import Store from "../../types/stores";
import { clickFreeSearch } from "../../utils/services/google/analytics";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Autocomplete } from "@retailtune/react-ui-core";
import { createAutocompleteHandler, retailTuneAutocompleteHandler } from "@retailtune/utils";
import { googleAutocompleteHandler } from "@retailtune/google-maps-utils";
import "./TopArea.css";
import { useConfiguration } from "../../context/configuration";

export const ranges = [
  createKmRange({ value: 10, mapZoom: 12 }),
  createKmRange({ value: 50, mapZoom: 10 }),
  createKmRange({ value: 100, mapZoom: 8 }),
];

interface TopAreaProps {
  autocompleteValue: string;
  setAutocompleteValue: React.Dispatch<React.SetStateAction<string>>;
}

export default function Toparea({ autocompleteValue, setAutocompleteValue }: TopAreaProps) {
  const {
    apiKey,
    storesConfiguration: { stores },
  } = useConfiguration();

  const currentDevice = useDevice();

  const {
    position: { isUserGeolocated },
    setCurrentPosition,
  } = usePosition();

  const {
    directions: { directionsActive },
    clearDirections,
  } = useDirections();

  const { setPartialBackgroundVisible } = useBackground();

  useEffect(() => {
    const autocompleteInputEl = document.querySelector(".rt-top-area .rt-autocomplete .user-input") as HTMLInputElement;

    // handles overlay visibility when user type in the input
    function handleAutocompleteInput (e: Event) {
      const text: string = (e as any).target.value;
      if (text.trim().length > 0) {
        setPartialBackgroundVisible(true);
      } else {
        setPartialBackgroundVisible(false);
      }
    }
    // handles overlay visibility when user click outside input
    function handleAutocompleteFocus (e: Event) {
      if (autocompleteInputEl.contains(e.target as Node)) {
        setPartialBackgroundVisible(true);
      } else {
        setPartialBackgroundVisible(false);
      }
    }

    autocompleteInputEl.addEventListener("input", handleAutocompleteInput);
    document.addEventListener("click", handleAutocompleteFocus);

    return () => {
      autocompleteInputEl.removeEventListener("input", handleAutocompleteInput);
      document.removeEventListener("click", handleAutocompleteFocus);
    }
  }, []);

  const countries = useMemo(() => {
    return Array.from(new Set(stores.map(s => s.country.tagISO31661Alpha2)));
  }, [stores]);

  const searchHandler = useCallback(
    createAutocompleteHandler(
      retailTuneAutocompleteHandler(apiKey, {
        language: RuntimeData.language,
        countries,
      }),
      googleAutocompleteHandler()
    ),
    [countries]
  );

  const predictionClickHandler = useCallback(
    (prediction: PredictionData) => {
      if (!(prediction.latitude && prediction.longitude)) {
        throw new Error(AutocompleteErrors.BAD_PREDICTION_REPRESENTATION);
      }
      if (directionsActive) {
        clearDirections();
      }
      setCurrentPosition(
        createPosition({
          latitude: prediction.latitude,
          longitude: prediction.longitude,
        })
      );
    },
    [directionsActive]
  );

  return (
    <div className="rt-top-area">
      {currentDevice === Device.DESKTOP && (
        <hgroup className="rt-top-area__titles">
          <h1 className="rt-top-area__title">{RuntimeData.translations.header.title}</h1>
          <h2 className="rt-top-area__subtitle">{RuntimeData.translations.header.subtitle}</h2>
        </hgroup>
      )}
      <Autocomplete
        value={autocompleteValue}
        setValue={setAutocompleteValue}
        searchHandler={searchHandler}
        predictionClickHandler={predictionClickHandler}
        zeroResultsMessage={RuntimeData.translations.autocomplete.zeroResultsMessage}
        placeholder={RuntimeData.translations.autocomplete.placeholder}
        clearIcon={{
          path: "/img/icon/close.svg",
          position: "left",
        }}
        searchIcon={{
          path: "/img/icon/search.svg",
          position: "right",
        }}
      />
      {(isUserGeolocated || autocompleteValue !== "") && <RadiusBar ranges={ranges} />}
    </div>
  );
}
