import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import Store, { Service } from "../../types/stores";

import { markersMap } from "../Map/MapView";
import AccordionMenu from "../AccordionMenu/Menu";

import "./StoreCard.css";
import { getWeekOpeningHours } from "../../utils/time/time";
import { RuntimeData } from "../../runtime";
import { Device } from "../../types/device";
import { SelectedStore, selectedStoreInitialValue } from "../StoreLocator/StoreLocator";
import {
    AnalyticsParams,
    clickDirectinsListing,
    clickPhoneListing,
    clickStoreDetailsListing,
} from "../../utils/services/google/analytics";

function getAddress(store: Store) {
    return store.address2 ? `${store.address1}, ${store.address2}` : store.address1;
}

function getCityInfo(store: Store) {
    const province = store.country.tagISO31661Alpha2.toLowerCase() === "it" ? `${store.province}, ` : "";
    return `${store.city}, ${province}${store.country.tagISO31661Alpha2.toUpperCase()}`;
}

interface StoreCardProps {
    store: Store;
    selectedStore: SelectedStore;
    setSelectedStore: React.Dispatch<React.SetStateAction<SelectedStore>>;
    currentDevice: Device;
    services: Service[];
    handleDrivingDirectionsRequest: (store: Store) => void;
    handleStoreDetailsRequest: (store: Store) => void;
    onlyListIsShown: boolean;
}

export default function StoreCard({
    store,
    selectedStore,
    setSelectedStore,
    currentDevice,
    services,
    handleDrivingDirectionsRequest,
    handleStoreDetailsRequest,
    onlyListIsShown,
}: StoreCardProps) {
    function handleStoreCardClick() {
        // if (onlyListIsShown && currentDevice !== Device.DESKTOP) return;
        // const marker = markersMap[store.storeCode];
        // if (marker) {
        //     google.maps.event.trigger(marker, "click");
        // }
        setSelectedStore((prev) => ({
            ...prev,
            storeCode: store.storeCode,
            markerSelected: true,
            cardSelected: prev.storeCode === store.storeCode,
        }));
    }

    const analyticsParams: AnalyticsParams = {
        language: RuntimeData.language,
        device: currentDevice,
        storeDetails: { storeCode: store.storeCode, address: store.address1, city: store.city },
    };

    function onDrivingDirectionsRequest() {
        handleDrivingDirectionsRequest(store);
        setSelectedStore(selectedStoreInitialValue);
        clickDirectinsListing(analyticsParams);
    }

    function onStoreDetailsRequest() {
        handleStoreDetailsRequest(store);
        setSelectedStore(selectedStoreInitialValue);
        clickStoreDetailsListing(analyticsParams);
    }

    function onPhoneClick() {
        clickPhoneListing(analyticsParams);
    }

    function showServices() {
        return store.services.length === 0
            ? []
            : [
                  <h1 key="_default" className="rt-store-card__services-title">
                      {RuntimeData.translations.storeCard.services}
                  </h1>,
                  ...services.filter((s) => store.services.includes(s.id)).map((s) => <p key={s.id}>{s.name}</p>),
              ];
    }

    function showOpeningTimes() {
        const weeklyOpeningHours = getWeekOpeningHours(store.openingHours);
        return weeklyOpeningHours.length === 0
            ? []
            : [
                  <h1 key="_default" className="rt-store-card__opening-times-title">
                      {RuntimeData.translations.storeCard.openings}
                  </h1>,
                  ...weeklyOpeningHours.map((woh) => (
                      <p key={woh.dayName}>
                          {woh.dayName}:{" "}
                          {woh.hours === "x" || !woh.hours
                              ? RuntimeData.translations.openingHours.closed.temporarily
                              : woh.hours}
                      </p>
                  )),
              ];
    }

    function showSpecialOpeningTimes() {
        return store.specialOpeningHours.length === 0
            ? []
            : [
                  <h1 key="_default" className="rt-store-card__opening-times-title">
                      {RuntimeData.translations.storeCard.specialOpenings}
                  </h1>,
                  ...store.specialOpeningHours.map((so) => (
                      <p>
                          <strong>{so.date}</strong>:
                          {so.close
                              ? ` ${RuntimeData.translations.openingHours.closed.temporarily}`
                              : ` ${so.morningOpen}-${so.morningClose}`.concat(
                                    so.eveningOpen !== "" ? `, ${so.eveningOpen}-${so.eveningclose}` : ""
                                )}
                      </p>
                  )),
              ];
    }

    const handleSetAccordionOpen = useCallback(
        (isOpen: boolean) => {
            const selected: SelectedStore = isOpen
                ? { storeCode: store.storeCode, cardSelected: true, markerSelected: true }
                : selectedStoreInitialValue;
            setSelectedStore(selected);
        },
        [selectedStore.storeCode]
    );

    return (
        <li
            id={`store-card__${store.storeCode}`}
            className={classNames("rt-store-card", {
                "rt-store-card--selected": selectedStore.markerSelected && selectedStore.storeCode === store.storeCode,
                "rt-store-card--floating": !onlyListIsShown,
            })}
            onClick={handleStoreCardClick}
        >
            {/* <div className="rt-store-card__wrapper"> */}
                <article className="rt-store-card__infos">
                    <section className="rt-store-card__main-infos">
                        <p>{store.name}</p>
                        <p>{getAddress(store)}</p>
                    </section>

                    <section className="rt-store-card__geo-infos">
                        <p>{getCityInfo(store)}</p>
                        <p>
                            {RuntimeData.translations.storeCard.phone}:{" "}
                            <a
                                className="rt-store-card__phone-number"
                                href={`tel:${store.phone}`}
                                onClick={onPhoneClick}
                            >
                                {store.phone}
                            </a>
                        </p>
                    </section>
                </article>

                <AccordionMenu
                    isOpen={selectedStore.cardSelected && selectedStore.storeCode === store.storeCode}
                    setOpen={handleSetAccordionOpen}
                    name={RuntimeData.translations.storeCard.buttonMoreDetails}
                    openIcon="/img/icon/meno.svg"
                    closedIcon="/img/icon/plus.svg"
                    stopPropagationOnClick={true}
                >
                    <article className="rt-store-card__accordion">
                        <section className="rt-store-card__services">
                            {selectedStore.cardSelected &&
                                selectedStore.storeCode === store.storeCode &&
                                showServices()}
                        </section>
                        <section className="rt-store-card__opening-times">
                            {selectedStore.cardSelected &&
                                selectedStore.storeCode === store.storeCode &&
                                showOpeningTimes()}
                        </section>
                        <section className="rt-store-card__special-opening-times">
                            {selectedStore.cardSelected &&
                                selectedStore.storeCode === store.storeCode &&
                                showSpecialOpeningTimes()}
                        </section>
                    </article>
                </AccordionMenu>

                {selectedStore.cardSelected && selectedStore.storeCode === store.storeCode && (
                    <div className="rt-store-card__buttons">
                        <button className="rt-store-card__button-store-info" onClick={onStoreDetailsRequest}>
                            {RuntimeData.translations.storeCard.buttonStoreDetails}
                        </button>
                        <button className="rt-store-card__button-directions" onClick={onDrivingDirectionsRequest}>
                            {RuntimeData.translations.storeCard.buttonDrivingDirections}
                        </button>
                    </div>
                )}
            {/* </div> */}
        </li>
    );
}
