import React, { ReactNode, useEffect, useState, createContext, useContext } from "react";
import { Device } from "../types/device";

interface DeviceProvider {
    children: ReactNode | ReactNode[];
}

function getDevice(): Device {
    if (window.innerWidth < 768) return Device.MOBILE;
    if (window.innerWidth < 991) return Device.TABLET;
    return Device.DESKTOP;
}

const initialState = getDevice();

const DeviceContext = createContext(initialState);

export function DeviceProvider({ children }: DeviceProvider) {
    const [device, setDevice] = useState<Device>(initialState);

    useEffect(() => {
        function _handleDeviceChange() {
            setDevice(getDevice)
        }

        window.addEventListener("resize", _handleDeviceChange);

        return () => window.removeEventListener("resize", _handleDeviceChange);
    }, []);

    return <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>;
}

export const useDevice = () => useContext(DeviceContext);
