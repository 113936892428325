import Position from "./types/position";
import Store, { Service } from "./types/stores";
import Translations from "./types/translations";

export class RuntimeData {
    static host: string;
    static language: string = "en";
    static serverTime: number;
    static hour: number;
    static minutes: number;
    static translations: Translations;
    static defaultPosition: Position;
    static stores: Store[];
    static services: Service[];
    static directionsStoreCode: string | null;
} 