import React, { createContext, ReactNode, useContext, useState } from "react";

interface State {
    isTotalBackgroundVisible: boolean;
    setTotalBackgroundVisible: (isVisible: boolean) => void;
    isPartialBackgroundVisible: boolean;
    setPartialBackgroundVisible: (isVisible: boolean) => void;
}

const state: State = {
    isTotalBackgroundVisible: false,
    setTotalBackgroundVisible(isVisible) {},
    isPartialBackgroundVisible: false,
    setPartialBackgroundVisible(isVisible) {},
};

const BackgroundContext = createContext(state);

interface BackgroundProviderProps {
    children: ReactNode | ReactNode[];
}

export function BackgroundProvider({ children }: BackgroundProviderProps) {
    const [isPartialBackgroundVisible, setPartialBackgroundVisible] = useState(false);
    const [isTotalBackgroundVisible, setTotalBackgroundVisible] = useState(false);

    return (
        <BackgroundContext.Provider
            value={{
                isTotalBackgroundVisible,
                setTotalBackgroundVisible,
                isPartialBackgroundVisible,
                setPartialBackgroundVisible,
            }}
        >
            {children}
        </BackgroundContext.Provider>
    );
}

export const useBackground = () => useContext(BackgroundContext);
