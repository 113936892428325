import React, { useCallback, useEffect, useState } from "react";
import { usePosition } from "../../context/position";
import { useDirections } from "../../context/directions";

import Store from "../../types/stores";
import ListView from "../List/ListView";

import MapView, {
  map,
  markersMap,
  STORE_MARKER_SELECTED_ICON,
  STORE_MARKER_ICON,
} from "../Map/MapView";

import "./StoreLocator.css";
import { useDevice } from "../../context/device";
import { Device } from "../../types/device";
import { getGoogleMapsLink } from "../../utils/services/google/maps";
import { useBackground } from "../../context/backgroundContext";
import classNames from "classnames";
import TopArea from "../TopArea/TopArea";
import FiltersArea from "../FiltersArea/FiltersArea";
import DirectionsPane from "../DirectionsPane/DirectionsPane";
import { StoresConfiguration } from "../../types/configuration";
import ConsentModal from "../consent-modal/ConsentModal";
import { RuntimeData } from "../../runtime";
import CookiesBar from "../cookie-bar/CookiesBar";
import { getConsentData, saveExpirable } from "../../utils/local-storage";
import { LOCAL_STORAGE_USER_COOKIE_CONSENT } from "../../constants/position";
import { ConsentData } from "../../types/local-storage";
import { ExpirationTime } from "../../utils/time/time-expiration";
import { ExpirationType } from "../../types/time";
import { clickFindNearestStores } from "../../utils/services/google/analytics";
import { useConfiguration } from "../../context/configuration";

export interface SelectedStore {
  storeCode: string | null;
  markerSelected: boolean;
  cardSelected: boolean;
}

export const selectedStoreInitialValue: SelectedStore = {
  storeCode: null,
  markerSelected: false,
  cardSelected: false,
};
interface BackgroundProperties {
  setSelectedStore: React.Dispatch<React.SetStateAction<SelectedStore>>;
}

function BlurredBackground({ setSelectedStore }: BackgroundProperties) {
  const { isTotalBackgroundVisible, isPartialBackgroundVisible } = useBackground();

  function handleBackgroundClick() {
    setSelectedStore(selectedStoreInitialValue);
  }

  return (
    <div
      id="blurred_background"
      className={classNames("blurred-background", {
        "blurred-background--total": isTotalBackgroundVisible,
        "blurred-background--partial": isPartialBackgroundVisible,
      })}
      onClick={handleBackgroundClick}
    ></div>
  );
}

export default function StoreLocator() {
  const { storesConfiguration: configuration } = useConfiguration();

  const {
    position: { currentPosition, isUserPositionConsentModalVisible },
    getUserPosition,
    setUserPositionConsent,
    setUserPositionConsentModalVisibility,
  } = usePosition();

  const {
    directions: { directionsActive },
    clearDirections,
    setDestinationStore,
  } = useDirections();

  const currentDevice = useDevice();

  // all the filtered stores
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);
  // only the currently visible stores
  const [visibleStores, setVisibleStores] = useState<Store[]>([]);

  const [listOnly, setListOnly] = useState(false);

  const [selectedStore, setSelectedStore] = useState<SelectedStore>(selectedStoreInitialValue);

  const [autocompleteValue, setAutocompleteValue] = useState("");

  useEffect(() => setSelectedStore(selectedStoreInitialValue), [currentDevice, listOnly]);

  const [userCookieConsent, setUserCookieConsent] = useState(() => {
    let userCookiesConsent = getConsentData(LOCAL_STORAGE_USER_COOKIE_CONSENT);
    if (!userCookiesConsent) userCookiesConsent = false;
    return userCookiesConsent;
  });

  function updateUserCookieConsent(consent: boolean) {
    const cookieConsent: ConsentData = {
      consent,
      expiration: new ExpirationTime(1, ExpirationType.YEARS).timeInMillis(),
    };
    saveExpirable(LOCAL_STORAGE_USER_COOKIE_CONSENT, cookieConsent);
    setUserCookieConsent(consent);
  }

  const handleDrivingDirectionsRequest = useCallback(
    (store: Store) => {
      if (currentDevice === Device.DESKTOP) {
        setDestinationStore(store);
      } else {
        const url = getGoogleMapsLink(store);
        window.open(url, "_blank")?.focus();
      }
    },
    [currentDevice]
  );

  const handleStoreDetailsRequest = useCallback((store: Store) => {
    window.open(store.storeLink, "_blank")?.focus();
  }, []);

  function handleListVisibilityToggle() {
    return setListOnly(prev => !prev);
  }

  function handleFindNearestStore() {
    if (directionsActive) {
      clearDirections();
    }
    getUserPosition();
    clickFindNearestStores();
  }

  return (
    <>
      <div className="rt-store-locator">
        <TopArea
          autocompleteValue={autocompleteValue}
          setAutocompleteValue={setAutocompleteValue}
        />
        <FiltersArea
          stores={configuration.stores}
          allServices={configuration.services}
          preselectedServices={configuration.selectedServices}
          setFilteredStores={setFilteredStores}
        />
        <DirectionsPane />
        <ListView
          stores={visibleStores}
          selectedStore={selectedStore}
          setselectedStore={setSelectedStore}
          allServices={configuration.services}
          onlyListIsShown={currentDevice === Device.DESKTOP || (listOnly && !directionsActive)}
          setOnlyListIsShown={setListOnly}
          handleDrivingDirectionsRequest={handleDrivingDirectionsRequest}
          handleStoreDetailsRequest={handleStoreDetailsRequest}
        />
        <div
          className={classNames("rt-map-area", {
            "rt-map-area--hidden": listOnly && currentDevice !== Device.DESKTOP,
          })}
        >
          {!directionsActive && (
            <button className="rt-map-area__find-nearest-button" onClick={handleFindNearestStore}>
              <img src="/img/icon/geo.svg" alt="" />
              {currentDevice === Device.DESKTOP && (
                <span>{RuntimeData.translations.topArea.buttonNearestStore}</span>
              )}
            </button>
          )}

          {!directionsActive && currentDevice !== Device.DESKTOP && (
            <button
              className="rt-map-area__list-toggler-button"
              onClick={handleListVisibilityToggle}
            >
              <img src="/img/icon/list.svg" alt="" />
            </button>
          )}
          <MapView
            center={currentPosition}
            stores={filteredStores}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            setStores={setVisibleStores}
            setAutocompleteValue={setAutocompleteValue}
            handleDrivingDirectionsRequest={handleDrivingDirectionsRequest}
            handleStoreDetailsRequest={handleStoreDetailsRequest}
          />
        </div>
      </div>
      {currentDevice !== Device.DESKTOP && (
        <BlurredBackground setSelectedStore={setSelectedStore} />
      )}
      <ConsentModal
        isModalVisible={isUserPositionConsentModalVisible}
        setModalVisibility={setUserPositionConsentModalVisibility}
        setUserConsent={setUserPositionConsent}
      />
      {!userCookieConsent && (
        <CookiesBar
          bodyText={RuntimeData.translations.cookieBar.text}
          cookiePolicyLink={RuntimeData.translations.cookieBar.link}
          cookiePolicyText={RuntimeData.translations.cookieBar.linkText}
          buttonText={RuntimeData.translations.cookieBar.buttonAccept}
          setUserConsent={updateUserCookieConsent}
        />
      )}
    </>
  );
}
