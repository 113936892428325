import { State } from "../context/position";
import {
    Action,
    isUpdateCurrentPositionAction,
    isUpdateUserPositionAction,
    isUpdateUserPositionConsentAction,
    isUpdateUserPositionConsentModalVisibleAction,
} from "../actions/position";

export default function PositionReducer(state: State, action: Action): State {
    if (isUpdateCurrentPositionAction(action)) {
        return { ...state, currentPosition: action.payload.currentPosition };
    }
    if (isUpdateUserPositionAction(action)) {
        return {
            ...state,
            isUserGeolocated: action.payload.isUserGeolocated,
            currentPosition: action.payload.userPosition,
            userPosition: action.payload.currentPosition,
        };
    }
    if (isUpdateUserPositionConsentAction(action)) {
        return {
            ...state,
            isUserPositionConsentActive: action.payload.userPositionConsent,
        };
    }
    if (isUpdateUserPositionConsentModalVisibleAction(action)) {
        return {
            ...state,
            isUserPositionConsentModalVisible:
                action.payload.isUserPositionConsentModalVisible,
        };
    }
    return state;
}
