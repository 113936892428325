import { GeolocationErrors } from "../errors";
import Position, { PositionType, Range } from "../types/position";
import { RuntimeData } from "../runtime";
import {
    ACCURACY_THRESHOLD,
    EARTH_RADIUS_IN_KM,
    LOCAL_STORAGE_DEFAULT_POSITION,
    LOCAL_STORAGE_USER_POSIITON,
} from "../constants/position";
import { getPositionData, saveExpirable } from "./local-storage";
import { PositionData } from "../types/local-storage";
import { ExpirationTime } from "./time/time-expiration";
import { ExpirationType } from "../types/time";
import { geoDefaultSuccess, geoDefaultSuccessCookie, geoError, geoIpError, geoIpSuccess, geoIpSuccessCookie, geoSuccess, geoSuccessCookie } from "./services/google/analytics";

interface CreatePositionProps {
    latitude: number;
    longitude: number;
    accuracy?: number;
    src?: PositionType;
}
export function createPosition({
    latitude,
    longitude,
    accuracy,
    src,
}: CreatePositionProps): Position {
    accuracy = accuracy ? accuracy : 1;
    src = src ? src : PositionType.FIXED;
    return { latitude, longitude, accuracy, src };
}

export function positionToLatLng(position: Position) {
    return {
        lat: position.latitude,
        lng: position.longitude,
    };
}

export const PLACEHOLDER_POSITION = createPosition({
    latitude: 11,
    longitude: 8,
});

export async function getDefaultPosition() {
    // .. read from local storage ..
    let defaultPosition = getPositionData(LOCAL_STORAGE_DEFAULT_POSITION);
    if (defaultPosition) return defaultPosition;

    // .. if no position found then fetch the position
    defaultPosition = createPosition({
        latitude: 41.8945853,
        longitude: 12.4130852,
    });

    // .. saving the position for latter use
    const positionData : PositionData = {
        position: defaultPosition,
        expiration: new ExpirationTime(1, ExpirationType.HOURS).timeInMillis()
    }
    saveExpirable(LOCAL_STORAGE_DEFAULT_POSITION, positionData);

    return defaultPosition;
}

const degToRad = (deg: number) => deg * 0.0174533;
/**
 * returns the distance in km between the points (lat1, lon1), (lat2, lon2)
 */
export function getDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    //source for the formula and for earth's radius: http://janmatuschek.de/LatitudeLongitudeBoundingCoordinates
    //dist = arccos(sin(lat1) · sin(lat2) + cos(lat1) · cos(lat2) · cos(lon1 - lon2)) · R
    const r1 = degToRad(lat1),
        r2 = degToRad(lat2),
        r3 = degToRad(lon1),
        r4 = degToRad(lon2);
    return (
        Math.acos(
            Math.sin(r1) * Math.sin(r2) + Math.cos(r1) * Math.cos(r2) * Math.cos(r3 - r4)
        ) * EARTH_RADIUS_IN_KM
    );
}

export async function geolocateUser(defaultPosition: Position): Promise<Position> {
    function saveUserPosition(position: Position) {
        const positionData: PositionData = {
            position,
            expiration: new ExpirationTime(15, ExpirationType.MINUTES).timeInMillis(),
        };
        saveExpirable(LOCAL_STORAGE_USER_POSIITON, positionData);
    }
    // .. fetching position from local storage
    try {
        const position = getPositionData(LOCAL_STORAGE_USER_POSIITON);
        if (!position) throw new Error();
        
        switch(position.src) {
            case PositionType.HTML5: {
                geoSuccessCookie();
                break;
            }
            case PositionType.IP: {
                geoIpSuccessCookie();
                break;
            }
            default: {
                geoDefaultSuccessCookie();
            }
        }
        return position;
    } catch (e) {}

    // .. fetching position from html5
    try {
        const position = await html5Geolocation(ACCURACY_THRESHOLD);
        saveUserPosition(position);
        geoSuccess();
        return position;
    } catch (e) {
        geoError();
    }

    geoDefaultSuccess();
    return defaultPosition;
}

export async function html5Geolocation(accuracyThreshold: number): Promise<Position> {
    if (!navigator.geolocation) throw new Error(GeolocationErrors.GEOLOCATION_DISABLED);
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) =>
                position.coords.accuracy > accuracyThreshold
                    ? reject(GeolocationErrors.ACCURACY_NOT_ENOUGH)
                    : resolve(
                          createPosition({
                              latitude: position.coords.latitude,
                              longitude: position.coords.longitude,
                              accuracy: position.coords.accuracy,
                          })
                      ),
            (e) => reject(e),
            {
                enableHighAccuracy: true,
                timeout: 3000,
            }
        );
    });
}

interface CreateKmRangeProps {
    value: number;
    mapZoom: number;
    text?: string;
}

export function createKmRange({ value, mapZoom, text }: CreateKmRangeProps): Range {
    return { value, mapZoom, text: text ? text : `${value} Km` };
}
