import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Store, { Service } from "../../types/stores";
import StoreCard from "./StoreCard";

import "./ListView.css";
import { usePosition } from "../../context/position";
import {
  map,
  markersMap,
  STORE_MARKER_SELECTED_ICON,
  STORE_MARKER_ICON,
} from "../Map/MapView";
import { positionToLatLng } from "../../utils/position";
import { useBackground } from "../../context/backgroundContext";
import { useDevice } from "../../context/device";
import { Device } from "../../types/device";
import { RuntimeData } from "../../runtime";
import { SelectedStore, selectedStoreInitialValue } from "../StoreLocator/StoreLocator";

interface ListViewProps {
  stores: Store[];
  selectedStore: SelectedStore;
  setselectedStore: React.Dispatch<React.SetStateAction<SelectedStore>>;
  allServices: Service[];
  onlyListIsShown: boolean;
  setOnlyListIsShown: (onlyListIsShown: boolean) => void;
  handleDrivingDirectionsRequest: (store: Store) => void;
  handleStoreDetailsRequest: (store: Store) => void;
}

export default function ListView({
  stores,
  selectedStore,
  setselectedStore,
  allServices,
  onlyListIsShown,
  setOnlyListIsShown,
  handleDrivingDirectionsRequest,
  handleStoreDetailsRequest,
}: ListViewProps) {
  const currentDevice = useDevice();

  const {
    position: { userPosition },
    setCurrentPosition,
  } = usePosition();

  const { setTotalBackgroundVisible } = useBackground();

  const [services, setServices] = useState<Service[]>(allServices);

  useEffect(() => {
    if (onlyListIsShown) {
      setTotalBackgroundVisible(false);
    } else {
      setTotalBackgroundVisible(
        !!(selectedStore.cardSelected && selectedStore.storeCode)
      );
    }
  }, [selectedStore.storeCode, selectedStore.cardSelected, onlyListIsShown]);

  // useEffect(() => {
  //     // this effect must run on every device change, since the blurred background
  //     // unmounts after a transition into desktop (therefore the listener must be created anew)

  //     function _handleMouseUp(e: MouseEvent) {
  //         let elem = e.target as HTMLElement;
  //         if (elem.classList.contains("blurred-background--total")) {
  //             setselectedStore(selectedStoreInitialValue);
  //         }
  //     }

  //     const blurredBackground = document.getElementById("blurred_background");

  //     setTimeout(() => {
  //         if (blurredBackground) {
  //             blurredBackground.addEventListener("mouseup", _handleMouseUp);
  //         }
  //     }, 300);

  //     return () => {
  //         if (blurredBackground) {
  //             blurredBackground.removeEventListener("mouseup", _handleMouseUp);
  //         }
  //     };
  // }, [currentDevice]);

  function handleViewChange() {
    setselectedStore(selectedStoreInitialValue);
    setOnlyListIsShown(false);
  }

  function handleChangeToUserPosition() {
    setCurrentPosition(userPosition);
    map.setCenter(positionToLatLng(userPosition));
  }


  return (
    <div
      className={classNames("rt-list-view", {
        "rt-list-view--floating": !onlyListIsShown,
        "rt-list-view--click-disabled":
          selectedStore.cardSelected && selectedStore.storeCode !== null,
      })}
    >
      {onlyListIsShown && currentDevice !== Device.DESKTOP && (
        <li
          className="rt-list-view__find-nearest-stores-button"
          onClick={handleChangeToUserPosition}
        >
          <img src="/img/icon/geo.svg" alt="" />
          <span>{RuntimeData.translations.topArea.buttonNearestStore}</span>
        </li>
      )}
      {onlyListIsShown && currentDevice !== Device.DESKTOP && (
        <button className="rt-list-view__show-map-button" onClick={handleViewChange}>
          <img src="/img/icon/map_white.svg" alt="" />
        </button>
      )}
      <ul
        className={classNames("rt-list-view__cards", {
          "rt-list-view__cards--floating": !onlyListIsShown,
        })}
      >
        {stores.map((s) => (
          <StoreCard
            key={s.storeCode}
            store={s}
            selectedStore={selectedStore}
            setSelectedStore={setselectedStore}
            currentDevice={currentDevice}
            services={services}
            handleDrivingDirectionsRequest={handleDrivingDirectionsRequest}
            handleStoreDetailsRequest={handleStoreDetailsRequest}
            onlyListIsShown={onlyListIsShown}
          />
        ))}
      </ul>
    </div>
  );
}
