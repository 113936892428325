import React, { MouseEvent, ReactNode, useEffect, useRef } from "react";
import classNames from "classnames";
import "./Menu.css";

interface AccordionMenuProps {
    children: ReactNode;
    name: string;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    openIcon?: string;
    closedIcon?: string;
    onMenuOpen?: () => void;
    onMenuClose?: () => void;
    delayOpening?: number;
    stopPropagationOnClick?: boolean;
}

export default function AccordionMenu({
    children,
    name,
    isOpen,
    setOpen,
    openIcon,
    closedIcon,
    onMenuOpen,
    onMenuClose,
    delayOpening = 0,
    stopPropagationOnClick,
}: AccordionMenuProps) {
    const panelRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function _openMenu() {
            if (panelRef.current) {
                panelRef.current.style.maxHeight = panelRef.current.scrollHeight + "px";
            }
        }

        function _closeMenu() {
            if (panelRef.current) {
                panelRef.current.style.maxHeight = "";
            }
        }
        if (isOpen) {
            setTimeout(_openMenu, delayOpening);
            if (onMenuOpen) {
                onMenuOpen();
            }
        } else {
            _closeMenu();
            if (onMenuClose) {
                onMenuClose();
            }
        }
    }, [isOpen]);

    function handleMenuClick(e: MouseEvent<HTMLButtonElement>) {
        if (stopPropagationOnClick) {
            e.stopPropagation();
        }
        setOpen(!isOpen);
    }

    return (
        <>
            <button
                className={classNames("rt-accordion__button-main", {
                    "rt-accordion__button-main--open": isOpen,
                })}
                onClick={handleMenuClick}
            >
                <img
                    className="rt-accordion__icon"
                    src={isOpen ? openIcon : closedIcon}
                    alt=""
                />
                {name}
            </button>
            <div
                ref={panelRef}
                className={classNames("rt-accordion__items", {
                    "rt-accordion__items--visible": isOpen,
                })}
            >
                {children}
            </div>
        </>
    );
}
