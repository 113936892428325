import { createKmRange } from "../utils/position";

export const EARTH_RADIUS_IN_KM = 6371;

export const ACCURACY_THRESHOLD = 20000; // meters

export const LOCAL_STORAGE_USER_COOKIE_CONSENT = "consent-cookies";

export const LOCAL_STORAGE_USER_POSITION_CONSENT = "consent-user-position";

export const LOCAL_STORAGE_DEFAULT_POSITION = "default-position";

export const LOCAL_STORAGE_USER_POSIITON = "user-position";

export const LOCAL_STORAGE_CURRENT_POSITION = "current-position";