import React from "react";

import "./CookiesBar.css";

interface CookieBarProps {
    bodyText: string;
    buttonText: string;
    cookiePolicyLink: string;
    cookiePolicyText: string;
    setUserConsent: (userCookieConsent: boolean) => void;
}

export default function CookiesBar({
    bodyText,
    buttonText,
    cookiePolicyLink,
    cookiePolicyText,
    setUserConsent,
}: CookieBarProps) {
    return (
        <div className="cookiesbar">
            <p>
                {bodyText}
                <a target="_blank" href={cookiePolicyLink}>
                    {cookiePolicyText}
                </a>
            </p>
            <button type="button" onClick={() => setUserConsent(true)}>
                {buttonText}
            </button>
        </div>
    );
}
