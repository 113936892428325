import React, { lazy, useEffect, useState } from "react";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import Position from "../../types/position";
import Store from "../../types/stores";
import { createDebounceFn } from "../../utils/fns";
import { createPosition, getDistance, positionToLatLng } from "../../utils/position";
import { sortByDistance, sortByPriority } from "../../utils/sorting";
import { useDirections } from "../../context/directions";
import { DirectionsErrors } from "../../errors";
import { DirectionsData } from "../../types/directions";
import { mapStyles } from "../../constants/map";
import { usePosition } from "../../context/position";
import { toast } from "react-toastify";
import { RuntimeData } from "../../runtime";
import { SelectedStore } from "../StoreLocator/StoreLocator";

import "./MapView.css";

// this is the actual map ref
export let map: google.maps.Map;

// user marker
let userMarker: google.maps.Marker;

// store markers
let markers: google.maps.Marker[] = [];

// store markers map - used in StoreCard
export let markersMap: { [key: string]: google.maps.Marker } = {};

// the markerClusterer ref
let markerClusterer: MarkerClusterer;

// directions service for driving directions
const directionsService = new google.maps.DirectionsService();

// directions renderer for driving directions
const directionsRenderer = new google.maps.DirectionsRenderer({
    markerOptions: {
        visible: false,
    },
    polylineOptions: {
        strokeColor: "#00a95c",
    },
});

// destination marker for driving directions
let destinationMarker: google.maps.Marker | null;

// bounds limit for the map
const allowedMapBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng({ lat: 30.944785000604583, lng: -10.585859425000006 }),
    new google.maps.LatLng({ lat: 57.01989907084408, lng: 28.964921824999994 })
);

const listenersHash: { [k: string]: google.maps.MapsEventListener } = {};

// marker icons path
const USER_MARKER_ICON = "/img/pin/apoint.svg";
export const STORE_MARKER_ICON = "/img/pin/bpoint_white.svg";
export const STORE_MARKER_SELECTED_ICON = "/img/pin/bpoint.svg";
const MARKER_CLUSTERER_ICON = "/img/cluster/m";

// debounce function used in "updateVisibleStores" function
const debounce = createDebounceFn();
const debounce1 = createDebounceFn();
const debounce2 = createDebounceFn();

// programmatically control the grid size of the clusterer
function updateClustererGridSize() {
    if (!map) return;

    const mapZom = map.getZoom();
    if (!mapZom) return;

    try {
        if (mapZom < 6) {
            return markerClusterer.setGridSize(60);
        }
        if (mapZom < 8) {
            return markerClusterer.setGridSize(40);
        }
        if (mapZom < 10) {
            return markerClusterer.setGridSize(15);
        }
        if (mapZom < 14) {
            return markerClusterer.setGridSize(7);
        }

        markerClusterer.setGridSize(5);
    } catch (e) {}
}

// create a new marker object on the map
function createMarker(map: google.maps.Map, position: google.maps.LatLngLiteral, icon: string) {
    return new google.maps.Marker({ map, position, icon });
}

interface MapViewProps {
    center: Position;
    stores: Store[];
    selectedStore: SelectedStore
    setSelectedStore: React.Dispatch<React.SetStateAction<SelectedStore>>;
    setStores: (stores: Store[]) => void;
    setAutocompleteValue: React.Dispatch<React.SetStateAction<string>>;
    handleDrivingDirectionsRequest: (store: Store) => void;
    handleStoreDetailsRequest: (store: Store) => void;
}

export default function MapView({
    center,
    stores,
    selectedStore,
    setSelectedStore,
    setStores,
    setAutocompleteValue,
    handleDrivingDirectionsRequest,
    handleStoreDetailsRequest,
}: MapViewProps) {
    const {
        position: { isUserGeolocated, defaultPosition },
    } = usePosition();
    const {
        directions: { directionsActive, origin, destinationStore, directionsInfo },
        setDirectionsInstructions,
    } = useDirections();

    const [currentMapCenter, setCurrentMapCenter] = useState<google.maps.LatLng>();

    function createMap(center: Position, defaultPosition: Position, isUserGeolocated: boolean) {
        const cntr = positionToLatLng(center);
        // init the map the first time
        map = new google.maps.Map(document.getElementById("rt_map_view")!, {
            center: cntr,
            zoom: 7,
            restriction: {
                latLngBounds: allowedMapBounds,
                strictBounds: false,
            },
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            styles: mapStyles,
        });

        // init user marker
        if (center.latitude !== defaultPosition.latitude || center.longitude !== defaultPosition.longitude) {
            userMarker = createMarker(map, cntr, USER_MARKER_ICON);
            map.setZoom(10);
        }

        // init marker clusterer
        markerClusterer = new MarkerClusterer(map, markers, {
            imagePath: MARKER_CLUSTERER_ICON,
        });

        map.addListener("center_changed", () => {
            debounce2(setCurrentMapCenter, 300, map.getCenter());
        });
        // on zoom change update clusterer grid size
        map.addListener("zoom_changed", updateClustererGridSize);
    }

    function updateMap(center: Position, isUserGeolocated: boolean) {
        const cntr = positionToLatLng(center);
        // if a map has been initialized already, just updates its center
        map.setCenter(cntr);
        map.setZoom(10);
        if (userMarker) {
            userMarker.setPosition(cntr);
        } else if (isUserGeolocated) {
            userMarker = createMarker(map, cntr, USER_MARKER_ICON);
        }
        return;
    }

    useEffect(() => {
        for (let i = 0; i < markers.length; ++i) {
            markers[i].setIcon(STORE_MARKER_ICON);
        }
        if (selectedStore.storeCode && selectedStore.markerSelected) {
            markersMap[selectedStore.storeCode].setIcon(STORE_MARKER_SELECTED_ICON);
        }
    }, [selectedStore.storeCode, selectedStore.markerSelected]);

    useEffect(() => {
        // this effect runs when the store locator "currentPosition" changes.
        // it initializes the map the first time, and updates its center afterward

        map ? updateMap(center, isUserGeolocated) : createMap(center, defaultPosition, isUserGeolocated);
    }, [center, isUserGeolocated]);

    useEffect(() => {
        if (!(center && currentMapCenter)) return;
        const distance = getDistance(center.latitude, center.longitude, currentMapCenter.lat(), currentMapCenter.lng());

        // console.table({
        //     currentPosition: `${center.latitude}, ${center.longitude}`,
        //     mapCenter: `${currentMapCenter?.lat()}, ${currentMapCenter?.lng()}`,
        //     distance,
        // });
        if (distance > 10) setAutocompleteValue("");
        setCurrentMapCenter(undefined);
    }, [center, currentMapCenter]);

    useEffect(() => {
        // this effect is meant to run when stores array changes, to provide the updated bounds_changed listener.
        // Also, the other effect dependencies are used within the debounced callback
        if (map && stores.length > 0) {
            map.addListener("bounds_changed", () => debounce(updateVisibleStores, 300));
        }
        if (!directionsActive) {
            const centerPosition = positionToLatLng(center);
            if (userMarker) {
                userMarker.setPosition(centerPosition);
            }
        }
    }, [map, stores, center, directionsActive]);

    useEffect(() => {
        // this effect is meant to run to create or delete the markers on the map when
        // switching between driving directions enabled/disabled. It also update destination marker
        // and directions renderer accordingly
        if (map && markerClusterer && stores.length > 0) {
            // clearing all markers & references
            markerClusterer.clearMarkers();
            Object.keys(markersMap).forEach((k) => {
                delete markersMap[k];
            });
            markers = [];

            if (directionsActive) {
                // repositioning user to driving directions origin
                const originPosition = positionToLatLng(origin);
                if (!userMarker) {
                    userMarker = createMarker(map, originPosition, USER_MARKER_ICON);
                } else {
                    userMarker.setPosition(originPosition);
                }
            } else {
                // clearing all driving directions instructions related stuff from the map
                // to redraw all the markers
                if (destinationMarker) {
                    destinationMarker.setMap(null);
                    destinationMarker = null;
                }

                try {
                    // an error is always throws for setDirections because of the null, but it
                    // does not cause any problem
                    directionsRenderer.setMap(null);
                    directionsRenderer.setDirections({ routes: [] });
                } catch (e) {}

                for (let i = 0; i < stores.length; ++i) {
                    const store = stores[i];
                    const marker = createMarker(map, { lat: store.latitude, lng: store.longitude }, STORE_MARKER_ICON);
                    addInfoWindow(marker, store);
                    markers.push(marker);
                    markersMap[store.storeCode] = marker;
                }
                markerClusterer.addMarkers(markers);
                updateVisibleStores();
                // console.log("BIG EXPENSIVE OPERATION EXECUTED");
            }
        }
    }, [map, stores, directionsActive, origin]);

    useEffect(() => {
        // this effect runs when a driving directions request is forwarded to the application state
        // containing a new destination store. It fetches directions for that store and updates the
        // map accordingly
        function prepareMap() {
            if (!(map && destinationStore)) return;

            // console.log(`o-lat:${origin.latitude}, o-lng: ${origin.longitude}`);

            const destination = positionToLatLng(
                createPosition({
                    latitude: destinationStore.latitude,
                    longitude: destinationStore.longitude,
                })
            );

            const request: google.maps.DirectionsRequest = {
                origin: positionToLatLng(origin),
                destination,
                travelMode: directionsInfo.travelMode,
            };

            directionsService.route(request, (directionsResult, directionsStatus) => {
                if (!directionsResult) {
                    console.error(DirectionsErrors.NULL_RESULT);
                    return toast(RuntimeData.translations.warnings.directions.genericError);
                }
                if (directionsStatus === "ZERO_RESULTS") {
                    console.error(DirectionsErrors.ZERO_RESULTS);
                    return toast(RuntimeData.translations.warnings.directions.noResults);
                }
                if (directionsStatus === "OK") {
                    // console.log(directionsResult);
                    const path = directionsResult.routes[0].legs[0];
                    const directionsData: DirectionsData = {
                        steps: path.steps,
                        time: path.duration?.text ?? "",
                        distance: path.distance?.text ?? "",
                    };
                    setDirectionsInstructions(directionsData);
                    directionsRenderer.setMap(map);
                    directionsRenderer.setDirections(directionsResult);
                    destinationMarker = createMarker(map, destination, STORE_MARKER_SELECTED_ICON);
                }
            });
        }

        if (directionsActive) {
            debounce1(prepareMap, 200);
        }
    }, [directionsActive, origin, destinationStore, directionsInfo.travelMode]);

    function addInfoWindow(marker: google.maps.Marker, store: Store) {
        // updates a marker with an info window
        marker.addListener("click", () => {

            const storePosition = createPosition({
                latitude: store.latitude,
                longitude: store.longitude,
            });
            map.setCenter(positionToLatLng(storePosition));

            setSelectedStore({
                storeCode: store.storeCode,
                markerSelected: true,
                cardSelected: false
            });

            const storeCard = document.getElementById(`store-card__${store.storeCode}`);

            setTimeout(() => storeCard?.scrollIntoView(), 400);
        });
    }

    function updateVisibleStores() {
        // updates visible stores list
        if (directionsActive || !(map && markerClusterer)) return;

        const bounds = map.getBounds();
        if (!bounds) return;

        let visibleStores: Store[] = [];

        const mapCenter: Position = createPosition({
            latitude: map.getCenter()?.lat() ?? center.latitude,
            longitude: map.getCenter()?.lng() ?? center.longitude,
        });

        for (let i = 0; i < stores.length; ++i) {
            const store = stores[i];
            const storePosition = {
                lat: store.latitude,
                lng: store.longitude,
            };
            const storesWithinBounds = bounds.contains(storePosition);
            if (storesWithinBounds) {
                // pushing store to be visible
                const distance = getDistance(mapCenter.latitude, mapCenter.longitude, store.latitude, store.longitude);
                store.distance = distance;
                visibleStores.push(store);
            }
        }

        const visibles = visibleStores.sort(sortByDistance);
        // console.log(visibles);
        setStores(visibles);
    }

    return <div id="rt_map_view"></div>;
}
