import { State } from "../context/directions";
import {
    Action,
    isUpdateOriginPosition,
    isUpdateDestinationStoreAction,
    isClearDirectionsAction,
    isUpdateTravelModeAction,
    isUpdateDirectionsInstructionsAction,
    isUpdateAutocompleteValueAction,
    isUpdateUserPositionAction,
} from "../actions/directions";
import { TravelMode } from "../types/directions";

export default function directionsReducer(state: State, action: Action): State {
    if (isClearDirectionsAction(action)) {
        return {
            ...state,
            directionsActive: false,
            origin: state.userPosition,
            destinationStore: null,
            directionsInfo: {
                travelMode: TravelMode.DRIVING,
                steps: [],
                time: "",
                distance: "",
            },
            autocompleteValue: "",
        };
    }
    if (isUpdateUserPositionAction(action)) {
        return {
            ...state,
            userPosition: action.payload.userPosition,
            origin: action.payload.userPosition
        }
    }
    if (isUpdateOriginPosition(action)) {
        return {
            ...state,
            origin: action.payload.position,
        };
    }
    if (isUpdateDestinationStoreAction(action)) {
        return {
            ...state,
            directionsActive: true,
            destinationStore: action.payload.store,
        };
    }
    if (isUpdateTravelModeAction(action)) {
        return {
            ...state,
            directionsInfo: {
                ...state.directionsInfo,
                travelMode: action.payload.travelMode,
            },
        };
    }
    if (isUpdateDirectionsInstructionsAction(action)) {
        return {
            ...state,
            directionsInfo: {
                ...state.directionsInfo,
                steps: action.payload.steps,
                time: action.payload.time,
                distance: action.payload.distance,
            },
        };
    }
    if (isUpdateAutocompleteValueAction(action)) {
        return {
            ...state,
            autocompleteValue: action.payload.autocompleteValue,
        };
    }
    return state;
}
