import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDirections } from "../../context/directions";
import { TravelMode } from "../../types/directions";
import "./DirectionsPane.css";
import { RuntimeData } from "../../runtime";
import { AutocompleteErrors } from "../../errors";
import { createPosition } from "../../utils/position";
import Store from "../../types/stores";
import { usePosition } from "../../context/position";
import { clickFreeSearchDirections } from "../../utils/services/google/analytics";
import { PredictionData } from "@retailtune/types/lib/autocomplete";
import { Autocomplete } from "@retailtune/react-ui-core";
import { createAutocompleteHandler, retailTuneAutocompleteHandler } from "@retailtune/utils";
import { googleAutocompleteHandler } from "@retailtune/google-maps-utils";
import { useConfiguration } from "../../context/configuration";

export default function DirectionsPane() {
  const {
    apiKey,
    storesConfiguration: { stores },
  } = useConfiguration();

  const { getUserPosition } = usePosition();

  const {
    directions: { directionsActive, destinationStore, directionsInfo, autocompleteValue },
    clearDirections,
    setTravelMode,
    setOrigin,
    setAutocompleteValue,
  } = useDirections();

  const [currentTravelMode, setCurrentTravelMode] = useState<TravelMode>();

  useEffect(() => {
    setCurrentTravelMode(() => directionsInfo.travelMode);
  }, [directionsInfo.time]);

  function handleSetUserPositionAsOrigin() {
    getUserPosition();
  }

  function handleClose() {
    clearDirections();
  }

  function handleTravelModeChange(travelMode: TravelMode) {
    setTravelMode(travelMode);
  }

  const countries = useMemo(() => {
    return Array.from(new Set(stores.map(s => s.country.tagISO31661Alpha2)));
  }, [stores]);

  const searchHandler = useCallback(
    createAutocompleteHandler(
      retailTuneAutocompleteHandler(apiKey, {
        language: RuntimeData.language,
        countries,
      }),
      googleAutocompleteHandler()
    ),
    [countries]
  );

  const predictionClickHandler = useCallback((prediction: PredictionData) => {
    if (!(prediction.latitude && prediction.longitude)) {
      throw new Error(AutocompleteErrors.BAD_PREDICTION_REPRESENTATION);
    }

    setOrigin(
      createPosition({
        latitude: prediction.latitude,
        longitude: prediction.longitude,
      })
    );

    clickFreeSearchDirections();
  }, []);

  return (
    <div
      className={classNames("rt-directions-pane", {
        "rt-directions-pane--open": directionsActive,
      })}
    >
      <button className="rt-directions-pane__button-close" onClick={handleClose}>
        <img src="/img/icon/close_panel.svg" alt="X" />
      </button>
      {destinationStore && (
        <hgroup className="rt-directions-pane__titles">
          <h1 className="rt-directions-pane__title">{destinationStore.name}</h1>
          <h2 className="rt-directions-pane__subtitle">
            {RuntimeData.translations.directionsPane.title}
          </h2>
        </hgroup>
      )}
      <ul className="rt-directions-pane__travel-modes">
        <li
          className={classNames("rt-directions-pane__travel-mode", {
            "rt-directions-pane__travel-mode--selected":
              directionsInfo.travelMode === TravelMode.WALKING,
          })}
          onClick={() => handleTravelModeChange(TravelMode.WALKING)}
        >
          <object type="image/svg+xml" data="/img/icon/feet.svg" />
          {currentTravelMode === TravelMode.WALKING && <span>{directionsInfo.time}</span>}
        </li>
        <li
          className={classNames("rt-directions-pane__travel-mode", {
            "rt-directions-pane__travel-mode--selected":
              directionsInfo.travelMode === TravelMode.DRIVING,
          })}
          onClick={() => handleTravelModeChange(TravelMode.DRIVING)}
        >
          <object type="image/svg+xml" data="/img/icon/car.svg" />
          {currentTravelMode === TravelMode.DRIVING && <span>{directionsInfo.time}</span>}
        </li>
        <li
          className={classNames("rt-directions-pane__travel-mode", {
            "rt-directions-pane__travel-mode--selected":
              directionsInfo.travelMode === TravelMode.TRANSIT,
          })}
          onClick={() => handleTravelModeChange(TravelMode.TRANSIT)}
        >
          <object type="image/svg+xml" data="/img/icon/train.svg" />
          {currentTravelMode === TravelMode.TRANSIT && <span>{directionsInfo.time}</span>}
        </li>
      </ul>

      <div className="rt-directions-pane__inputs">
        <button
          className="rt-directions-pane__origin-button"
          onClick={handleSetUserPositionAsOrigin}
        >
          <img className="rt-directions-pane__geo-icon" src="/img/icon/geo.svg" alt="geo" />
          <span className="rt-directions-pane__geo-text">
            {RuntimeData.translations.topArea.buttonNearestStore}
          </span>
        </button>

        <div className="rt-directions-pane__input-wrapper">
          <Autocomplete
            value={autocompleteValue}
            setValue={setAutocompleteValue as React.Dispatch<React.SetStateAction<string>>}
            searchHandler={searchHandler}
            predictionClickHandler={predictionClickHandler}
            zeroResultsMessage={RuntimeData.translations.autocomplete.zeroResultsMessage}
            placeholder={RuntimeData.translations.directionsPane.autocompletePlaceholder}
          />
          <img src="/img/pin/apoint.svg" alt="origin" />
        </div>

        <div className="rt-directions-pane__input-wrapper">
          <input
            className="rt-directions-pane__destination-input"
            disabled
            value={`${destinationStore?.address1}, ${destinationStore?.city}, ${destinationStore?.country.name}`}
          />
          <img src="/img/pin/bpoint_directions_pane.svg" alt="destination" />
        </div>
      </div>

      <ul className="rt-directions-pane__steps">
        <li className="rt-directions-pane__step">
          <p className="rt-directions-pane__step-text">
            {RuntimeData.translations.directionsPane.originText}
          </p>
          <img className="rt-directions-pane__step-icon" src="/img/pin/apoint.svg" alt="origin" />
        </li>
        {directionsInfo.steps.map((s, i) => (
          <li key={i} className="rt-directions-pane__step">
            <p
              className="rt-directions-pane__step-text"
              dangerouslySetInnerHTML={{
                __html: s.instructions,
              }}
            ></p>
            <span className="rt-directions-pane__step-distance">{s.distance?.text}</span>
          </li>
        ))}
        <li className="rt-directions-pane__step">
          <p className="rt-directions-pane__step-text">
            {RuntimeData.translations.directionsPane.destinationText}
          </p>
          <img
            className="rt-directions-pane__step-icon"
            src="/img/pin/bpoint_directions_pane.svg"
            alt="destination"
          />
        </li>
      </ul>
    </div>
  );
}
