
export enum PositionType {
    HTML5,
    IP,
    FIXED
}

export default interface Position {
    latitude: number;
    longitude: number;
    accuracy: number;
    src: PositionType
}

export interface Range {
    value: number,
    mapZoom: number,
    text: string
}