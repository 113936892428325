import { ConsentData, Expirable, PositionData } from "../types/local-storage";
import Position from "../types/position";

export function saveExpirable<T extends Expirable>(name: string, expirable: T) {
    localStorage.setItem(name, JSON.stringify(expirable));
}

function isExpirable(obj: Expirable): obj is Expirable {
    return obj.expiration !== undefined;
}

function isExpired(expirable: Expirable) {
    const now = new Date();
    const expiration = new Date(expirable.expiration);
    return now > expiration;
}

function getExpirableItem(name: string): Expirable | null {
    const item = localStorage.getItem(name);

    if (!item) return null;

    let parsedItem = JSON.parse(item);

    if (isExpirable(parsedItem) && !isExpired(parsedItem)) {
        return parsedItem;
    }

    return null;
}

function isConsentData(obj: Expirable): obj is ConsentData {
    return isExpirable(obj) && (obj as any).consent !== undefined;
}

export function getConsentData(name: string): boolean | null {
    const expirable = getExpirableItem(name);

    if (!expirable) {
        return null;
    }

    if (!isConsentData(expirable)) {
        throw new TypeError("type mismatch");
    }

    return expirable.consent;
}

function isPositionData(obj: Expirable): obj is PositionData {
    return isExpirable(obj) && (obj as any).position !== undefined;
}

export function getPositionData(name: string): Position | null {
    const expirable = getExpirableItem(name);

    if (!expirable) {
        return null;
    }

    if (!isPositionData(expirable)) {
        throw new TypeError("type mismatch");
    }

    return expirable.position;
}
