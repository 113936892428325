export enum TravelMode {
    WALKING = "WALKING",
    DRIVING = "DRIVING",
    TRANSIT = "TRANSIT",
}

export interface DirectionsData {
    steps: google.maps.DirectionsStep[];
    time: string;
    distance: string;
}

export type DirectionsInfo = DirectionsData & {
    travelMode: TravelMode;
};
