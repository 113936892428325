import { DirectionsData, TravelMode } from "../types/directions";
import Position from "../types/position";
import Store from "../types/stores";

export enum ActionType {
    CLEAR_DIRECTIONS = "CLEAR_DIRECTIONS",
    UPDATE_USER_POSITION = "UPDATE_USER_POSITION",
    UPDATE_ORIGIN_POSITION = "UPDATE_ORIGIN_POSITION",
    UPDATE_DESTINATION_STORE = "UPDATE_DESTINATION_STORE",
    UPDATE_TRAVEL_MODE = "UPDATE_TRAVEL_MODE",
    UPDATE_DIRECTIONS_INSTRUCTIONS = "UPDATE_DIRECTIONS_INSTRUCTIONS",
    UPDATE_AUTOCOMPLETE_VALUE = "UPDATE_AUTOCOMPLETE_VALUE",
}

export interface Action {
    type: ActionType;
}

export interface ClearDirections extends Action {
    payload: {};
}
export function isClearDirectionsAction(action: Action): action is ClearDirections {
    return action.type === ActionType.CLEAR_DIRECTIONS;
}

export interface UpdateUserPositionAction extends Action {
    payload: { userPosition: Position };
}
export function isUpdateUserPositionAction(
    action: Action
): action is UpdateUserPositionAction {
    return action.type === ActionType.UPDATE_USER_POSITION;
}

export interface UpdateOriginPosition extends Action {
    payload: { position: Position };
}
export function isUpdateOriginPosition(action: Action): action is UpdateOriginPosition {
    return action.type === ActionType.UPDATE_ORIGIN_POSITION;
}

export interface UpdateDestinationStore extends Action {
    payload: { store: Store };
}
export function isUpdateDestinationStoreAction(
    action: Action
): action is UpdateDestinationStore {
    return action.type === ActionType.UPDATE_DESTINATION_STORE;
}

export interface UpdateTravelMode extends Action {
    payload: { travelMode: TravelMode };
}
export function isUpdateTravelModeAction(action: Action): action is UpdateTravelMode {
    return action.type === ActionType.UPDATE_TRAVEL_MODE;
}

export interface UpdateDirectionsInstructions extends Action {
    payload: DirectionsData;
}
export function isUpdateDirectionsInstructionsAction(
    action: Action
): action is UpdateDirectionsInstructions {
    return action.type === ActionType.UPDATE_DIRECTIONS_INSTRUCTIONS;
}

export interface UpdateAutocompleteValue extends Action {
    payload: { autocompleteValue: string };
}
export function isUpdateAutocompleteValueAction(
    action: Action
): action is UpdateAutocompleteValue {
    return action.type === ActionType.UPDATE_AUTOCOMPLETE_VALUE;
}

export function clearDirections(): ClearDirections {
    return {
        type: ActionType.CLEAR_DIRECTIONS,
        payload: {},
    };
}

export function updateUserPosition(userPosition: Position): UpdateUserPositionAction {
    return {
        type: ActionType.UPDATE_USER_POSITION,
        payload: { userPosition },
    };
}

export function updateOriginPosition(position: Position): UpdateOriginPosition {
    return {
        type: ActionType.UPDATE_ORIGIN_POSITION,
        payload: { position },
    };
}

export function updateDestinationStore(store: Store): UpdateDestinationStore {
    return {
        type: ActionType.UPDATE_DESTINATION_STORE,
        payload: { store },
    };
}

export function updateTravelMode(travelMode: TravelMode): UpdateTravelMode {
    return {
        type: ActionType.UPDATE_TRAVEL_MODE,
        payload: { travelMode },
    };
}

export function updateDirectionsInstructions(
    directionsData: DirectionsData
): UpdateDirectionsInstructions {
    return {
        type: ActionType.UPDATE_DIRECTIONS_INSTRUCTIONS,
        payload: directionsData,
    };
}

export function updateAutocompleteValue(
    autocompleteValue: string
): UpdateAutocompleteValue {
    return {
        type: ActionType.UPDATE_AUTOCOMPLETE_VALUE,
        payload: { autocompleteValue },
    };
}
