
export enum GeolocationErrors {
    ACCURACY_NOT_ENOUGH = "Position accuracy is not enough",
    GEOLOCATION_DISABLED = "Geolocation is disabled in browser",
}

export enum AutocompleteErrors {
    BAD_PREDICTION_REPRESENTATION = "Selected prediction is not of the expected form"
}

export enum DirectionsErrors {
    NULL_RESULT = "result is null, unable to fetch any information",
    ZERO_RESULTS = "zero results for the request"
}

export enum OpeningsErrors {
    CASE_NOT_DEFINED = "Opening case is not defined",
    BAD_PARAMS = "Bad parameters provided to the opening text function",
    BAD_HOURS_REPRESENTATION = "Bad string representation for opening hours" 
}

export enum ApproximationErrors {
    BAD_DECIMAL_DIGITS_VALUE = "decimal digits must be greater or equal than zero",
    STRING_REPR_IS_NAN = "target string is NaN"
}