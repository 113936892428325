import React from "react";
import classnames from "classnames";
import { usePosition } from "../../context/position";

import "./ConsentModal.css";
import { saveExpirable } from "../../utils/local-storage";
import { LOCAL_STORAGE_USER_POSITION_CONSENT } from "../../constants/position";
import { clickConsentModal } from "../../utils/services/google/analytics";
import { ExpirationTime } from "../../utils/time/time-expiration";
import { ExpirationType } from "../../types/time";
import { ConsentData } from "../../types/local-storage";
import { RuntimeData } from "../../runtime";

interface CosnentModalProps {
    isModalVisible: boolean;
    setModalVisibility: (isModalVisible: boolean) => void;
    setUserConsent: (userConsent: boolean) => void;
}

export default function ConsentModal({ isModalVisible, setModalVisibility, setUserConsent }: CosnentModalProps) {
    function handleUserConsent(consent: boolean) {
        setUserConsent(consent);
        if (consent) {
            const userConsentData: ConsentData = {
                consent,
                expiration: new ExpirationTime(1, ExpirationType.YEARS).timeInMillis(),
            };
            saveExpirable(LOCAL_STORAGE_USER_POSITION_CONSENT, userConsentData);
        }
        clickConsentModal(consent);
        setModalVisibility(false);
        clickConsentModal(consent);
    }

    return (
        <div
            id="consent_modal"
            className={classnames("consent-modal-overlay", {
                "consent-modal-overlay--hidden": !isModalVisible,
            })}
        >
            <div className="consent-modal-popup">
                <img className="close" src="/img/icon/modal_close.svg" onClick={() => handleUserConsent(false)} />
                <div className="info-box">
                    <h2>{RuntimeData.translations.consentModal.title}</h2>
                    <p>{RuntimeData.translations.consentModal.body}</p>
                    <div className="buttons">
                        <button className="button--yes" onClick={() => handleUserConsent(true)}>
                            {RuntimeData.translations.consentModal.buttonYes}
                        </button>
                        <button className="button--no" onClick={() => handleUserConsent(false)}>
                            {RuntimeData.translations.consentModal.buttonNo}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
