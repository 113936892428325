import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";

import App from "./App";

import { RuntimeData } from "./runtime";
import Translations from "./types/translations";
import { getDefaultPosition } from "./utils/position";
import Store, { Service } from "./types/stores";
import { PositionConfiguration, StoreLocatorConfiguration, StoresConfiguration } from "./types/configuration";
import Position from "./types/position";

async function getHostname(key: string) {
    try {
        const response = await axios.post(
            "https://endpoint.retailtune.com/storelocator-widget/get-hostname-from-key",
            JSON.stringify({ key }),
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (response.data.result !== "ok") throw "invalid_key";
        return `https://${response.data.hostname}/`;
    } catch (e) {
        throw new Error("please use a valid API key.");
    }
}

enum InitMode {
    STANDARD = "standard",
    WIDGET = "widget",
}

interface UserConfiguration {
    apiKey: string;
    currentPosition: Position | undefined;
    language: string;
    selectedServices: number[];
}

(window as any).rt_gif = function getInitFunction(initMode: InitMode) {
    let alreadyInit = false;

    return async function ({ apiKey, currentPosition, language, selectedServices }: UserConfiguration) {
        if (alreadyInit) return;
        alreadyInit = true;

        try {
            const hostname = await getHostname(apiKey);

            if (initMode === InitMode.WIDGET) {
                // TODO add operations needed for widgets
            }

            const p1 = axios.get<Store[]>(`${hostname}js_db/stores-json-${language}.json`);
            const p2 = axios.get<Service[]>(`${hostname}js_db/services-json-${language}.json`);
            const p3 = getDefaultPosition();
            const p4 = axios.get<Translations>(`${hostname}translations/translations-${language}.json`);

            const [stores, services, defaultPosition, translations] = await Promise.all([p1, p2, p3, p4]);

            // console.log(services.data);

            const positionConfiguration: PositionConfiguration = {
                defaultPosition,
                userPosition: defaultPosition,
                currentPosition: currentPosition ?? defaultPosition,
            };

            const storesConfiguration: StoresConfiguration = {
                stores: stores.data,
                services: services.data,
                selectedServices,
            };
            const configuration: StoreLocatorConfiguration = {
                apiKey,
                positionConfiguration,
                storesConfiguration,
            };

            // console.log(translations.data);
            
            // .. setting up translations
            RuntimeData.translations = translations.data;

            ReactDOM.render(<App configuration={configuration} />, document.getElementById("rt_root"));
        } catch (e) {
            console.error(e);
        }
    };
};
