
export enum Openings {
    NO_OPENING_DETAILS,
    TODAY_CLOSED,
    CLOSED_OPENS_AT,
    CLOSED_REOPENS,
    CLOSED_REOPENS_AT,
    OPEN_UNTIL,
    OPEN_UNTIL_REOPENS_AT
}


export enum ExpirationType {
    MINUTES = "minutes",
    HOURS = "hours",
    DAYS = "days",
    MONTHS = "months",
    YEARS = "years"
} 

export interface TimeTextCache {
    [storeCode: string]: string
}

export interface OpeningDetails {
    hours: string,
    isToday: boolean,
    dayName: string
}

export interface OpeningsTranslations {
    todayClosed: string;
    closedOpensAt: string;
    closedReopens: string;
    closedReopensAt: string;
    openUntil: string;
    openUntilReopensAt: string;
}

export interface WeekOpeningHour {
    dayName: string;
    isToday: boolean;
    hours: string;
}

export interface OpeningHoutsFromServerTimeProps {
    serverTime: number;
    currentHour: number;
    currentMinutes: number;
    translations: OpeningsTranslations;
    storeInfo: {
        storeCode: string,
        openingHours: string[];
        weeklyOpeningDetails: OpeningDetails[];
        timeOffset: number;
    }
}

export interface SpecialOpening {
    date: string;
    close: boolean;
    morningOpen: string;
    morningClose: string;
    eveningOpen: string;
    eveningclose: string;
}